<template>
  <div class="ph-date-range-picker">
    <div class="ph-date-range-picker__content">
      <div class="ph-date-range-picker__input-wrapper">
        <PhDatepicker
          @input="handleStartInput"
          :future="future"
          :maxDate="end"
          :value="start"
          rounded
        />
      </div>
      <div class="ph-date-range-picker__input-wrapper">
        <PhDatepicker
          @input="handleEndInput"
          :future="future"
          :minDate="start"
          :value="end"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<script>
import PhDatepicker from '@/components/pickers/PhDatepicker';

export default {
  name: 'PhDateRangePicker',
  props: {
    start: [String, Number],
    end: [String, Number],
    future: Boolean,
  },
  components: {
    PhDatepicker,
  },
  methods: {
    handleEndInput(val) {
      this.$emit('endChange', val);
      this.$emit('change', { end: val });
      this.$emit('update:end', val);
    },
    handleStartInput(val) {
      this.$emit('startChange', val);
      this.$emit('change', { start: val });
      this.$emit('update:start', val);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';

.ph-date-range-picker__content {
  display: flex;
  flex-direction: column;
  @include bp-up(md) {
    flex-direction: row;
    align-items: center;
  }
}
.ph-date-range-picker__input-wrapper {
  width: 100%;
  &:nth-child(1n + 2) {
    margin-top: 16px;
  }
  @include bp-up(md) {
    width: auto;
    &:nth-child(1n + 2) {
      margin-top: 0px;
      margin-left: 30px;
    }
  }
}
</style>
