<template>
  <div :style="cssVariables" class="claim-status">
    <div v-if="prettyStatus" class="claim-status__content">
      <span v-if="hasIcon" class="claim-status__icon-wrapper">
        <span class="claim-status__dot"></span>
      </span>
      <span class="claim-status__value">{{ prettyStatus }}</span>
    </div>
  </div>
</template>

<script>
import {
  FNOL_STATUSES,
  FNOL_STATUS_NAMES,
  CLAIM_STATUSES,
  CLAIM_STATUS_NAMES,
} from '@/utils/constants';

export default {
  name: 'ClaimStatus',
  props: {
    defaultColor: {
      type: String,
      default: '#454B60',
    },
    errorColor: {
      type: String,
      default: '#C20505',
    },
    fnol: Boolean,
    hasIcon: Boolean,
    infoColor: {
      type: String,
      default: '#2287D0',
    },
    successColor: {
      type: String,
      default: '#1DE08D',
    },
    status: String,
    warningColor: {
      type: String,
      default: '#CEB340',
    },
  },
  computed: {
    color() {
      if (this.fnol) {
        switch (this.status) {
          case FNOL_STATUSES.PENDING:
            return this.infoColor;
          case FNOL_STATUSES.DECLINED:
            return this.errorColor;
          case FNOL_STATUSES.ACCEPTED:
            return this.successColor;
          case FNOL_STATUSES.CONFIRMED:
            return this.warningColor;
          default:
            return this.defaultColor;
        }
      } else {
        switch (this.status) {
          case CLAIM_STATUSES.PENDING:
            return this.infoColor;
          case CLAIM_STATUSES.DECLINED:
            return this.errorColor;
          case CLAIM_STATUSES.FULLY_PAID:
            return this.successColor;
          case CLAIM_STATUSES.PARTIALLY_PAID:
            return this.warningColor;
          default:
            return this.defaultColor;
        }
      }
    },
    cssVariables() {
      return `--color: ${this.color}`;
    },
    prettyStatus() {
      if (typeof this.status === 'string' && this.status.length) {
        const statusNameMap = this.fnol ? FNOL_STATUS_NAMES : CLAIM_STATUS_NAMES;
        const statusString = statusNameMap[this.status] || this.status.toLowerCase();
        return this.$capitalizeStart(statusString);
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
$dot-size: 8px;
.claim-status {
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
}
.claim-status__content {
  display: flex;
  align-items: center;
}
.claim-status__icon-wrapper,
.claim-status__dot,
.claim-status__value {
  display: block;
}
.claim-status__icon-wrapper {
  margin-right: 4px;
}
.claim-status__dot {
  background-color: var(--color);
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
}
.claim-status__value {
  color: var(--color);
}
</style>
