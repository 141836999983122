<template>
  <div class="claims-list-view">
    <v-container class="claims-list-view__container fill-height">
      <div class="claims-list-view__header">
        <v-container class="py-0">
          <v-layout
            class="claims-list-view__header-row claims-list-view__header-row--top"
            align-center
            justify-space-between
          >
            <PageTitle>FNOL's</PageTitle>
            <div class="claims-list-view__header-actions-group"></div>
          </v-layout>
        </v-container>
        <PageFilterBar
          class="claims-list-view__header-row claims-list-view__header-row--bottom"
          paddingX
        >
          <PageFilter>
            <div>
              <SelectFilter
                fullWidth
                v-model="fnolOrganizationsFilter"
                :items="organizationsOptions"
                placeholder="Organizations"
              >
              </SelectFilter>
              <div
                class="claim-list-filters__organization-info"
                v-if="fnolOrganizationsFilter.length"
              >
                <div class="claim-list-filters__organization-info-label">
                  Selected {{ fnolOrganizationsFilter.length }}
                  {{ fnolOrganizationsFilter.length > 1 ? 'organizations' : 'organization' }}
                </div>
                <div class="claim-list-filters__organization-info-btn">
                  <v-btn text small @click="fnolOrganizationsFilter = []">
                    Reset
                  </v-btn>
                </div>
              </div>
            </div>
          </PageFilter>
          <PageFilterGroup grow>
            <PageFilter type="search" wide>
              <SearchTextField v-model="fnolClaimantNameFilter" placeholder="Full Name" />
            </PageFilter>
            <PageFilter type="search" wide>
              <SearchTextField v-model="fnolSearchQueryFilter" placeholder="FNOL Number" />
            </PageFilter>
          </PageFilterGroup>
          <PageFilterGroup justify="between">
            <PageFilter type="date-range">
              <PhDateRangePicker
                :start.sync="fnolDateFromFilter"
                :end.sync="fnolDateToFilter"
                rounded
              />
            </PageFilter>
            <PageFilter>
              <SelectFilter
                v-model="fnolStatusFilter"
                :items="fnolFilterOptions.status"
                label="Filter by"
                placeholder="Status"
              >
              </SelectFilter>
            </PageFilter>
          </PageFilterGroup>
        </PageFilterBar>
      </div>
      <div class="claims-list-view__content pb-10">
        <div>
          <FnolsTable
            :items="computedFnols"
            :loading="loadingFnols"
            :sort-by="fnolFilters.sortBy"
            :sort-desc="fnolFilters.sortDesc"
            @update:sort-desc="handleSortType"
            @itemViewClick="handleShowClaim"
          >
          </FnolsTable>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { fnolFilters, queryToggle } from '@/mixins';
import { getClaimantAddressFromClaim } from '@/utils/helpers/app/claims';
import FnolsTable from '@/components/tables/FnolsTable';
import {
  PageFilter,
  PageFilterBar,
  PageFilterGroup,
  SearchTextField,
  SelectFilter,
} from '@/components/filters';
import PageTitle from '@/components/typography/PageTitle';
import PhDateRangePicker from '@/components/pickers/PhDateRangePicker';

export default {
  name: 'FnolsList',
  mixins: [fnolFilters, queryToggle],
  components: {
    FnolsTable,
    PageFilter,
    PageFilterBar,
    PageFilterGroup,
    PageTitle,
    PhDateRangePicker,
    SearchTextField,
    SelectFilter,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('claims', ['fnols', 'loadingFnols']),
    computedFnols() {
      if (this.fnols && this.fnols.length) {
        return this.fnols.map(fnol => {
          const fullName = `${fnol.claimantFirstName} ${fnol.claimantLastName}`;
          return {
            ...fnol,
            fullName,
            claimantEmail: fnol.claimantEmail,
            memberId: fnol.memberId,
            address: this.$formatAddress(getClaimantAddressFromClaim(fnol)),
          };
        });
      }
      return this.fnols;
    },
  },
  watch: {
    fnolFilters: {
      handler() {
        this.getFnols();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('claims', ['fetchFnols']),
    ...mapActions('app', ['openSidebarInfo']),
    queryToggleHandler({ target, type }) {
      switch (type) {
        case 'sidebar':
          this.openSidebarInfo({
            id: target,
            content: 'fnol',
          });
          break;
        default:
          break;
      }
    },
    async getFnols() {
      try {
        await this.fetchFnols();
        return true;
      } catch (error) {
        this.$dialog.error({ error });
        return false;
      }
    },
    handleShowClaim(id) {
      this.openSidebarInfo({
        id,
        content: 'fnol',
      });
    },
  },
  mounted() {
    this.getFnols();
  },
};
</script>

<style lang="scss">
@import '~@/scss/views/claims';
</style>
